var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"form-wrapper"},[_c('van-form',{ref:"form"},[_c('van-field',{attrs:{"readonly":"","disabled":"","name":"applyName","label":"用户名","placeholder":"请输入姓名","required":""},model:{value:(_vm.form.applyName),callback:function ($$v) {_vm.$set(_vm.form, "applyName", $$v)},expression:"form.applyName"}}),_c('van-field-select',{attrs:{"options":_vm.idCardTypes,"fieldProps":{
        label: '证件类型',
        required: true,
        readonly: true,
        disabled: true,
        placeholder: '请选择证件类型'
      }},model:{value:(_vm.form.idCardType),callback:function ($$v) {_vm.$set(_vm.form, "idCardType", $$v)},expression:"form.idCardType"}}),_c('van-field',{attrs:{"readonly":"","disabled":"","name":"idCardNum","label":"证件号码","placeholder":"请输入证件号码","required":""},model:{value:(_vm.form.idCardNum),callback:function ($$v) {_vm.$set(_vm.form, "idCardNum", $$v)},expression:"form.idCardNum"}}),_c('van-field',{attrs:{"name":"phone","label":"手机号","placeholder":"请输入手机号","required":"","maxlength":"11"},model:{value:(_vm.form.phone),callback:function ($$v) {_vm.$set(_vm.form, "phone", $$v)},expression:"form.phone"}}),_c('van-field',{attrs:{"name":"workCmpy","label":"工作单位","placeholder":"请输入工作单位","maxlength":"100"},model:{value:(_vm.form.workCmpy),callback:function ($$v) {_vm.$set(_vm.form, "workCmpy", $$v)},expression:"form.workCmpy"}}),_c('div',[_c('van-divider',{style:({
          color: '#1989fa',
          borderColor: '#1989fa',
          padding: '0rem 0.3rem'
        }),attrs:{"content-position":"left"}},[_vm._v("学历信息")]),_c('van-field',{attrs:{"name":"college","label":"毕业院校","placeholder":"请输入毕业院校","maxlength":"100","required":""},model:{value:(_vm.form.college),callback:function ($$v) {_vm.$set(_vm.form, "college", $$v)},expression:"form.college"}}),_c('van-field',{attrs:{"name":"major","label":"所学专业","placeholder":"请输入所学专业","maxlength":"100","required":""},model:{value:(_vm.form.major),callback:function ($$v) {_vm.$set(_vm.form, "major", $$v)},expression:"form.major"}}),_c('van-field-select',{attrs:{"options":_vm.educations,"fieldProps":{
          label: '学历',
          placeholder: '请选择学历',
          required: true
        }},model:{value:(_vm.form.education),callback:function ($$v) {_vm.$set(_vm.form, "education", $$v)},expression:"form.education"}}),_c('van-field-select',{attrs:{"options":_vm.form.education === '本科' ? _vm.educationType1 : _vm.educationTypes,"fieldProps":{
          label: '学历形式',
          placeholder: '请选择学历形式',
          required: true
        }},model:{value:(_vm.form.educationType),callback:function ($$v) {_vm.$set(_vm.form, "educationType", $$v)},expression:"form.educationType"}}),_c('van-field-calendar',{attrs:{"name":"graduateDate","label":"毕业时间","placeholder":"毕业时间","fieldProps":{
          label: '毕业时间',
          placeholder: '请选择',
          required: true
        }},model:{value:(_vm.form.graduateDate),callback:function ($$v) {_vm.$set(_vm.form, "graduateDate", $$v)},expression:"form.graduateDate"}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }