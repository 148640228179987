<template>
  <!--
  *@des: 人才公寓租赁申请
  -->
  <section class="form-wrapper">
    <van-form ref="form">
      <van-field
        readonly
        disabled
        v-model="form.applyName"
        name="applyName"
        label="用户名"
        placeholder="请输入姓名"
        required
      />
      <van-field-select
        v-model="form.idCardType"
        :options="idCardTypes"
        :fieldProps="{
          label: '证件类型',
          required: true,
          readonly: true,
          disabled: true,
          placeholder: '请选择证件类型'
        }"
      ></van-field-select>
      <van-field
        readonly
        disabled
        v-model="form.idCardNum"
        name="idCardNum"
        label="证件号码"
        placeholder="请输入证件号码"
        required
      />
      <van-field
        v-model="form.phone"
        name="phone"
        label="手机号"
        placeholder="请输入手机号"
        required
        maxlength="11"
      />
      <van-field
        v-model="form.workCmpy"
        name="workCmpy"
        label="工作单位"
        placeholder="请输入工作单位"
        maxlength="100"
      />

      <!-- 学历信息 -->
      <div>
        <van-divider
          content-position="left"
          :style="{
            color: '#1989fa',
            borderColor: '#1989fa',
            padding: '0rem 0.3rem'
          }"
          >学历信息</van-divider
        >
        <van-field
          v-model="form.college"
          name="college"
          label="毕业院校"
          placeholder="请输入毕业院校"
          maxlength="100"
          required
        />
        <van-field
          v-model="form.major"
          name="major"
          label="所学专业"
          placeholder="请输入所学专业"
          maxlength="100"
          required
        />
        <van-field-select
          v-model="form.education"
          :options="educations"
          :fieldProps="{
            label: '学历',
            placeholder: '请选择学历',
            required: true
          }"
        ></van-field-select>
        <van-field-select
          v-model="form.educationType"
          :options="form.education === '本科' ? educationType1 : educationTypes"
          :fieldProps="{
            label: '学历形式',
            placeholder: '请选择学历形式',
            required: true
          }"
        ></van-field-select>
        <!-- <van-field-select
          v-model="form.degree"
          :options="degrees"
          :fieldProps="{
            label: '学位',
            placeholder: '请选择学位',
            required: true,
          }"
        ></van-field-select> -->
        <van-field-calendar
          v-model="form.graduateDate"
          name="graduateDate"
          label="毕业时间"
          placeholder="毕业时间"
          :fieldProps="{
            label: '毕业时间',
            placeholder: '请选择',
            required: true
          }"
        />
      </div>

      <!-- 家庭关系 -->
      <!-- <div>
        <van-divider
          content-position="left"
          :style="{
            color: '#1989fa',
            borderColor: '#1989fa',
            padding: '0rem 0.3rem',
          }"
          >家庭关系</van-divider
        >
        <div v-for="(item, i) in form.family" :key="i">
          <van-field-select
            v-model="item.familyTies"
            :options="familyTies"
            :fieldProps="{
              label: '关系',
              required: item.familyName || item.famIdCardNum ? true : false,
              placeholder: '请选择关系',
            }"
          ></van-field-select>
          <van-field
            v-model="item.familyName"
            name="familyName"
            label="姓名"
            placeholder="请输入姓名"
            :required="item.familyName || item.famIdCardNum ? true : false"
            maxlength="20"
          />
          <van-field
            v-model="item.famIdCardType"
            name="famIdCardType"
            label="证件类型"
            placeholder="请输入证件类型"
            :required="item.familyName || item.famIdCardNum ? true : false"
            maxlength="100"
            disabled
          />
          <van-field
            v-model="item.famIdCardNum"
            name="famIdCardNum"
            label="身份证号"
            placeholder="请输入身份证号"
            :required="item.familyName || item.famIdCardNum ? true : false"
            maxlength="18"
          />
          <van-field-area
            v-model="item.famDomicile"
            name="famDomicile"
            :fieldProps="{
              required: item.familyName || item.famIdCardNum ? true : false,
              label: '户籍所在地',
              placeholder: '请选择户籍所在地',
            }"
            defaultField=""
          />
          <p class="fam-btns">
            <van-button class="btn" type="default" size="mini" @click="onAdd"
              >新增</van-button
            >
            <van-button
              class="btn"
              type="danger"
              size="mini"
              @click="onDelete(i)"
              >删除</van-button
            >
          </p>
        </div>
      </div> -->
    </van-form>
  </section>
</template>

<script>
import formMixin from "../mixin";
import Schema from "async-validator";

export default {
  name: "talent-house-apply",
  mixins: [formMixin],
  data() {
    return {
      form: {
        applyName: "", //姓名
        idCardType: "居民身份证", //证件类型
        phone: "",
        idCardNum: "", //证件号码
        workCmpy: "", //工作单位
        talentType: "本科及以上学历人才",
        //学历信息
        college: "",
        major: "",
        education: "",
        educationType: "",
        degree: "",
        graduateDate: "",
        //技能信息
        skillLevel: "", //职业技能等级
        skillCertName: "", //证书名称
        skillCertCode: "" //证书编号
        // family: [
        //   {
        //     familyTies: "",
        //     familyName: "",
        //     famIdCardType: "居民身份证",
        //     famIdCardNum: "",
        //     famDomicile: []
        //   }
        // ]
      },
      familyFormValidator1: null,
      familyFormValidator2: null,
      formRules: {
        phone: [
          {
            required: true,
            message: "请输入手机号"
          },
          {
            pattern:
              /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
            message: "请检查手机号格式"
          }
        ],
        workCmpy: {
          required: false,
          message: "请输入工作单位"
        }
      },
      educationFormRules: {
        college: {
          required: true,
          message: "请输入学校"
        },
        major: {
          required: true,
          message: "请输入专业"
        },
        education: {
          required: true,
          message: "请选择学历"
        },
        educationType: {
          required: true,
          message: "请选择学历形式"
        },

        graduateDate: {
          required: true,
          message: "请选择毕业时间"
        }
      },
      skillFormRules: {
        skillCertName: {
          required: true,
          message: "请输入证书名称"
        },
        skillCertCode: {
          required: true,
          message: "请输入证书编号"
        },
        skillLevel: {
          required: true,
          message: "请选择职业技能等级"
        }
      },
      familyformRules1: {
        familyTies: {
          required: false
        },
        familyName: {
          required: false
        },
        famIdCardNum: {
          required: false
        },
        famDomicile: {
          required: false
        }
      },
      familyformRules2: {
        familyTies: {
          required: true,
          message: "请选择家庭成员关系"
        },
        familyName: {
          required: true,
          message: "请输入姓名"
        },
        famIdCardNum: [
          {
            required: true,
            message: "请输入身份证号"
          },
          {
            pattern:
              /^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/,
            message: "请检查身份证号格式"
          }
        ],
        famDomicile: {
          required: true,
          type: "array",
          message: "请选择户籍地址"
        }
      },
      idCardTypes: [
        {
          value: "居民身份证",
          label: "居民身份证"
        }
      ],

      educations: [
        {
          value: "本科",
          label: "本科"
        },
        {
          value: "硕士",
          label: "硕士"
        },
        {
          label: "博士",
          value: "博士"
        }
      ],
      educationType1: [
        {
          value: "全日制",
          label: "全日制"
        }
      ],
      educationTypes: [
        {
          value: "全日制",
          label: "全日制"
        },
        {
          value: "非全日制",
          label: "非全日制"
        }
      ],
      degrees: [
        {
          value: "无",
          label: "无"
        },
        {
          value: "硕士",
          label: "硕士"
        },
        {
          label: "博士",
          value: "博士"
        }
      ],

      //家庭关系
      familyTies: [
        {
          value: "配偶",
          label: "配偶"
        },
        {
          value: "儿子(未成年)",
          label: "儿子(未成年)"
        },
        {
          label: "女儿(未成年)",
          value: "女儿(未成年)"
        }
      ]
    };
  },
  created() {
    // this.initFormValidator();
  },
  methods: {
    /*
     *@des: 初始化校验
     */
    initFormValidator() {
      this.familyFormValidator1 = new Schema(this.familyformRules1);
      this.familyFormValidator2 = new Schema(this.familyformRules2);
    },
    /*
     *@des: 校验家庭关系
     */
    async validateFamilyForm() {
      let len = this.form.family.length,
        isFormCorrect = true; //校验状态

      for (let i = 0; i < len; i++) {
        let item = this.form.family[i];
        try {
          //判断姓名，身份证号是否已填，不同校验
          if (item.familyName || item.famIdCardNum) {
            await this.familyFormValidator2.validate(item);
          } else {
            await this.familyFormValidator1.validate(item);
          }

          isFormCorrect = true;
        } catch ({ errors }) {
          isFormCorrect = false;

          let message = errors[0].message;
          this.$toast(`家庭关系第${i + 1}项${message}`);
          break;
        }
      }

      return isFormCorrect;
    },
    /*
     *@des: 校验表单
     */
    validate() {
      return new Promise((resolve, reject) => {
        let rules = {};
        if (this.form.talentType === "本科及以上学历人才") {
          rules = this.educationFormRules;
        }

        let validator = new Schema({
          ...rules,
          ...this.formRules
        });

        validator
          .validate(this.form, (errors) => {
            if (errors && errors.length) {
              this.$toast(errors[0].message);
            }
          })
          .then(async () => {
            // let familyFormStatus = await this.validateFamilyForm();
            // if (familyFormStatus) {
            //   let family = this.form.family
            //     .filter((item) => (item.familyName ? true : false))
            //     .map((item) => {
            //       let [famProCode, famCityCode, famCountyCode] =
            //         item.famDomicile;
            //       return {
            //         ...item,
            //         famProCode,
            //         famCityCode,
            //         famCountyCode
            //       };
            //     });
            //   resolve({
            //     ...this.form,
            //     family
            //   });
            // }
            resolve({
              ...this.form
            });
          })
          .catch((err) => {
            console.log(err);
          });
      });
    },
    /*
     *@des: 新增家庭成员
     */
    onAdd() {
      this.form.family.push({
        familyTies: "",
        familyName: "",
        famIdCardType: "居民身份证",
        famIdCardNum: "",
        famDomicile: []
      });
    },
    /*
     *@des: 删除家庭成员
     */
    onDelete(index) {
      if (this.form.family.length === 1) {
        this.$toast("最后一条不能删除");
        return;
      }
      this.form.family.splice(index, 1);
    }
  }
};
</script>

<style lang="scss" scoped>
.title-gutter {
  width: fit-content;
  margin-left: 0.3rem;
  padding: 0.3rem 0.1rem 0rem 0rem;
  font-size: 0.26rem;
  line-height: 0.26rem;
  border-bottom: 2px solid #3c37d2;
}
.fam-btns {
  padding: 0.3rem;
  margin-bottom: 0.3rem;
  border-bottom: 1px solid #eee;
  .btn {
    width: 1.5rem;
    margin-right: 0.15rem;
  }
}
</style>
